import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { removeSquareBrackets } from './../helpers/helper';
import { SocialShareButtons } from '../components/V2/News/SocialShareButtons/SocialShareButtons';

import styled from 'styled-components';
// Import components from newsStyles
import * as newsStyles from '../styles/newsStyles.jsx';
const { SingleNewsWrapper, SingleNewsMainContent, SingleNewsSideColumn, SingleNewsContent } = newsStyles;

import * as GlobalStyles from '../GlobalStyles.jsx';
const GlobalStyle = GlobalStyles.default;

// * Components
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BlogData } from '../components/V2/News/BlogData/BlogData';
import { NewsletterFormComponent } from '../components/V2/Form/NewsletterForm';

export const data = graphql`
  query ($slug: String) {
    dibPosts(slug: { eq: $slug }) {
      title
      summary
      slug
      publishedAt
      updatedAt
      readtime
      content
      featuredImage
      categories {
        title
        slug
      }
      author {
        name
        slug
        photo
      }
    }
  }
`;

interface author {
  name: string;
  slug: string;
  photo: string;
}

interface categories {
  title: string;
  slug: string;
}

interface dibPost {
  title: string;
  summary: string;
  publishedAt: string;
  updatedAt: string;
  readtime: number;
  content: string;
  featuredImage: string;
  author: {
    [key: string]: author;
  };
  categories: {
    [key: string]: categories;
  };
}

interface dibPosts {
  data: any;
  dibPost: dibPost[];
}

const PostPage = (props: dibPosts) => {
  let [post, setPost] = useState(props.data.dibPosts);
  const [mainCategory, setMainCategory] = useState(undefined);
  const [productCategory, setProductCategory] = useState(undefined);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [form, setForm] = useState(undefined);
  const [formTitle, setFormTitle] = useState(undefined);
  const [addRequestDemoCTA, setAddRequestDemoCTA] = useState(undefined);

  // useEffect(() => {
  //   fetch(
  //     `https://api.dropinblog.com/v1/json/post/?b=b069c92f-a309-49dd-afcd-7361d3d7dc8c&post=` + props.data.dibPosts.slug
  //   )
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       // If the post has been updated after it was imported as a static file update the page.
  //       if (props.data.dibPosts.updatedAt !== resultData.data.post.updatedAt) {
  //         setPost(resultData.data.post);
  //         // setForm(extractFormUrl(resultData.data.post.content));
  //       }

  //       setRelatedArticles(resultData.data.post.related_posts);
  //       // setForm(extractFormUrl(resultData.data.post.content));
  //       // setFormTitle(extractFormTitle(resultData.data.post.content));
  //       // setAddRequestDemoCTA(checkForDemoFormRequest(resultData.data.post.content));
  //       // setMainCategory(getPrimaryCateogory(resultData.data.post.categories));
  //       // setProductCategory(getPrimaryCateogory(resultData.data.post.categories));
  //     }); // set data for the post
  // }, [props.data.dibPosts.slug, props.data.dibPosts.updatedAt]);

  function bodyText() {
    let content = post.content;
    content = removeSquareBrackets(content);
    return {
      __html: content
    };
  }

  let headerLang = 'en';

  return (
    <PageContainer>
      <GlobalStyle />
      <HelmetContainer
        lang={headerLang}
        title={post.title}
        description={post.description}
        image={post.featuredImage}
      />
      <Header lang={headerLang} />

      <SingleNewsWrapper className={'container'}>
        <SingleNewsMainContent>
          <h1>{post.title}</h1>
          <hr />
          <BlogData Category={mainCategory} Author={post.author} Date={post.publishedAt} ReadTime={post.readtime} />

          <SingleNewsContent dangerouslySetInnerHTML={bodyText()}></SingleNewsContent>

          <SocialShareButtons />
        </SingleNewsMainContent>
        <SingleNewsSideColumn>
        </SingleNewsSideColumn>
      </SingleNewsWrapper>

      <div className={'spacing-xl'}></div>

      <div className={'container'}>
        <NewsletterFormComponent />
      </div>
      <Footer lang={headerLang} />
    </PageContainer>
  );
};

export default PostPage;
