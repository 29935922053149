import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../GlobalStyles";

export const ResultWrap = styled.div`
  margin: 32px 0;
  font-size: 18px;
  text-align: left;
  padding-bottom: 12px;
  color: ${colors.primary};
`

export const NoResultsDisplay = styled.div`
  font-size: 36px;
  width: 100%;
  grid-column: 1 / 5;
  padding: 100px 0;

  h2 {
    text-align: center;
  }
`
export const WebinarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  position: relative;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 64px;
  }
  h1 {
    font-size: 28px;
    color: ${colors.primary};
    font-weight: normal;
    line-height: 1.1;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 38px;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 56px;
    }
  }
  
  iframe{
    max-width: 100% !important;
    padding: 0;
    margin: 0;
    width: 100%;
    aspect-ratio: 5 / 3;
  }
  
  img.form-image{
    max-width: 100%;
  }
  
  div.copy {
    h1, h2, h3, h4, h5, h6, p, ul, li, a {
      //CLEARING STYLES
      font-weight: normal;
      font-family: poppins;
      color: ${colors.primary};
      margin: 0;
      padding: 0;
    }
    h3 {
      color: ${colors.teal};
      font-size: 24px;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      margin-bottom: 16px;
    }
    img{
      width: auto !important;
      height: auto !important;
      max-width: 100% !important;
    }
    ul {
      margin: 16px 0;
      li {
        font-size: 18px;
        color: ${colors.tertiary};
        list-style: disc;
        margin-left: 22px;
        padding-left: 6px;
        ul{
            margin: 0;
        }
      }
    }
    
    a{
      color: ${colors.teal};
    }
  }
  
`;

export const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  position: relative;


  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(4, 1fr);

    .SingleArticleTile {
      &.key-0,
      &.key-7 {
        grid-column: 1 / 3;

        .text-wrap {
          h4 {
            font-size: 32px;
          }
        }
      }

      &.key-8 {
        grid-column: 3 / 5;

        .text-wrap {
          h4 {
            font-size: 32px;
          }
        }
      }
    }
  }

`;

export const FilterContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 24px 0;

    div.divider {
        background: rgba(35, 64, 87, 0.2);
        width: 1px;
    }

    &.events-filters {
        justify-content: start;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        flex-direction: row;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding: 50px 0;
    }

`;

export const CheckboxWrapper = styled.div`

  width: 100%;
  margin-bottom: 34px;

  h3 {
    font-family: poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 16px;
  }

  label {
    display: flex;
    align-items: start;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    color: ${colors.UILightSecondary};
    margin-bottom: 8px;
    cursor: pointer;
    padding: 0 16px 0 0;

    &:hover {
      opacity: 0.8;
    }

  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
    border: solid 1px rgba(35, 64, 87, 0.2);
    border-radius: 2px;
    margin-right: 12px;
    display: inline-block;

    &:checked {
      background-image: url("/assets/icons/check-primary.png");
      background-size: 14px;
      background-position: center center;
      background-repeat: no-repeat;
    }

  }


  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: 30%;
    
    &.filters-topics {
      //width: 38%;
      //label{
      //  width: 50%;
      //  margin: 0;
      //  display: inline-block;
      //}
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 20%;
    &.filters-topics {
      width: 48%;
      label{
        width: 48%;
        padding: 0;
        display: inline-block;
      }
    }
  }

`;

export const NewsHeaderStyles = styled.div`
  padding: 24px 0;

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    color: ${colors.primary};
    margin-bottom: 36px;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  input[type='text'] {
    width: 100%;
    border: none;
    border-bottom: solid 1px rgba(35, 64, 87, 0.2);
    font-family: poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    padding: 16px 0;
    color: ${colors.primary};
    transition: 0.35s;
    margin-bottom: 16px;

    &::placeholder {
      color: ${colors.UILightTertiary};
    }

    :focus {
      outline: none;
      border-bottom: solid 1px ${colors.UILightTertiary};
    }

    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url('/assets/icons/search.png');

  }

  button {
    border: solid 1px rgba(35, 64, 87, 0.2);
    background: transparent;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    font-family: poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-left: 0;
    align-items: center;
    cursor: pointer;
    transition: 0.35s;

    img {
      margin-right: 8px;
      width: 16px;
    }

    &:hover {
      background: ${colors.UILightTertiary}
    }

  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 82px 0 32px;

    .row {
      display: flex;
      flex-direction: row;
    }

    input[type='text'] {
      margin-bottom: 0;
    }

    button {
      margin-left: 26px;
    }

    h1 {
      font-size: 40px;
    }
  }


`;

export const PaginationStyles = styled.div`
  // margin: 64px 0;
  text-align: center;
  border-bottom: solid 1px ${colors.UILightBordersSecondary};

  button, span {
    background: none;
    border: none;
    font-family: poppins;
    padding: 0 12px 12px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    margin: 0;
    top: 1px;
    color: ${colors.primary};

    &:disabled {
      color: ${colors.primary};
      border-bottom: solid 1px ${colors.primary};
    }

    &:hover {
      opacity: 0.6;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {


    button, span {
      margin: 0 12px;
      padding: 24px 10px;
    }


  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    margin: 148px 0;
  }


`;

export const SingleNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px auto;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin: 64px auto;
    flex-direction: row;
  }
`

export const NewsHRDivider = styled.hr`
  background: ${colors.highlight};
`;

export const SingleNewsMainContent = styled.div`
  h1 {
    font-size: 28px;
    font-family: poppins;
    color: ${colors.primary};
    line-height: 1.2;
    font-weight: 400;
  }

  hr {
    width: 100%;
    max-width: 360px;
    margin: 24px 0;
    background: ${colors.highlight};
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    h1 {
      font-size: 38px;
    }

    hr {
      margin: 36px 0;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    h1 {
      font-size: 56px;
    }

    hr {
      margin: 44px 0;
    }
  }
`
export const SingleNewsSideColumn = styled.div`

  width: 100%;

  h4 {
    margin-top: 36px;
    font-family: poppins;
    font-weight: 400;
    font-size: 22px;
    color: ${colors.primary};
    display: inline-flex;
    align-items: center;
    line-height: 1.2;
  }


  @media (min-width: ${mediaQueriesSizes.lg}px) {
    max-width: 210px;
    margin-left: 32px;
    h4 {
      font-size: 16px;
      margin-top: 0;
    }
  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    max-width: 210px;
    margin-left: 150px;
    h4 {
      margin-top: 0;
    }
  }

`

export const SingleNewsContent = styled.div`
    margin: 24px 0;
    font-family: poppins;
    color: ${colors.UILightSecondary};

    h2, h3, h4, h5, h6 {
        font-family: poppins;
        font-weight: 400;
        margin-bottom: 16px;
        color: ${colors.primary};
    }

    h3 {
        font-family: 'poppins';
        font-size: 22px;
        line-height: 1;
        margin-bottom: 12px;
        color: ${colors.teal};
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 16px;
    }

    p {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 1.5;

        sup{
            vertical-align: super;
            font-size: 0.6em;
        }
        sub{
            vertical-align: sub;
            font-size: 0.6em;
        }
    }
        

    em {
        font-size: 16px;
    }

    img {
        margin: 32px 0 0;
        width: 100%;
        height: auto;
    }

    a {
        color: ${colors.highlight};
    }

    ul {
        margin: 16px 0;

        li {
            font-size: 16px;
            list-style: initial;
            margin-left: 16px;
            line-height: 1.5;

            ul {
                margin: 0 0 8px;

                li {
                    font-size: 0.8em;
                    opacity: 0.8;
                    line-height: 1.5;

                    &::marker {
                        color: ${colors.teal};
                    }
                }
            }
        }

        h2 {
            margin: 36px 0 16px;
            font-size: 28px;
        }

        blockquote p {
            font-style: italic;
            line-height: 1.6;
            width: 85%;
            margin: 48px auto;
            font-size: 22px;
            font-family: 'poppins';
            position: relative;

            &:before {
                color: #234057;
                opacity: 0.07;
                content: "“";
                display: block;
                position: absolute;
                top: 15px;
                left: -60px;
                font-size: 150px;
                line-height: 0.5;
            }
        }

        h3 {
            font-family: 'poppins';
            font-size: 22px;
            line-height: 1;
            margin-bottom: 12px;
            color: ${colors.teal};
        }

        h4 {
            font-size: 18px;
        }

        h5 {
            font-size: 16px;
        }

        h6 {
            font-size: 16px;
        }

        p {
            margin-bottom: 32px;
            font-size: 16px;
            line-height: 1.5;
        }

        em {
            font-size: 16px;
        }

        img {
            margin: 32px 0 0;
            width: 100%;
            height: auto;
        }

        a {
            color: ${colors.highlight};
        }

        ul {
            margin: 16px 0;

            li {
                font-size: 16px;
                list-style: initial;
                margin-left: 16px;
                line-height: 1.5;
            }
        }

        @media (min-width: ${mediaQueriesSizes.md}px) {
            margin: 18px 0;

            h2 {
                font-size: 32px;
            }

            h3 {
                font-size: 26px;
            }

            h4 {
                font-size: 22px;
            }

            h5 {
                font-size: 20px;
            }

            h6 {
                font-size: 18px;
            }

            p {
                margin-bottom: 32px;
                font-size: 20px;
            }

            em {
                font-size: 16px;
            }

            blockquote p {
            }

            img {
                margin: 72px 0 0;
            }

            ul {
                margin: 32px 0;

                li {
                    font-size: 20px;
                    margin-left: 16px;
                }
            }

        }


`;
